"use client";
"use strict";

import { ReactNode, useEffect, useState } from "react";
import NotLoggedIn from "@/components/auth/NotLoggedIn";
import { useAuthState } from "react-firebase-hooks/auth";
import dynamic from "next/dynamic";
import { firebaseAuth } from "@/libs/firebase/clientApp";
import { registerUser } from "@/libs/firebase/analytics";
import { getProjectId } from "@/libs/teams/clientTeam";
const ErrorBox = dynamic(() => import("@/components/general/ErrorBox"));

interface RequireAuthWrapperProps {
  children?: ReactNode;
}

export default function RequireAuthWrapper({ children }: Readonly<RequireAuthWrapperProps>): ReactNode {
  // This page requires authentication, check we have that
  const [user, userLoading, error] = useAuthState(firebaseAuth);
  const [projectLoaded, setProjectLoaded] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>("");

  useEffect(() => {
    async function register() {
      if (!userLoading && user) {
        try {
          const teamId = await getProjectId(user);
          registerUser(teamId);
          setProjectId(teamId);
        }
        catch {
          // Do nothing - this is expected
          setProjectId("");
        }
        finally {
          setProjectLoaded(true);
        }
      }
    }
    register();
  }, [userLoading, user]);

  if (error) {
    return <ErrorBox title="Auth Error" body={error.message} />;
  }

  if (!userLoading) {
    if (user && projectLoaded) {
      if (projectId === "") {
        return <ErrorBox title="Unauthorized" body="You are not authorized to view this app due to missing a Project ID. If you believe this is an error please talk to a judge." />;
      }
      return <>{children}</>;
    }
    else if (!user) {
      return <NotLoggedIn />;
    }
  }
  return null;
}
