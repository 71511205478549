/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";
import { firebaseAuth, firestore } from "@/libs/firebase/clientApp";
import { getProjectId } from "@/libs/teams/clientTeam";
import { EventSchema, ScoreSchema, TaskConfig } from "@hacksday/libscore";
import { onSnapshot, doc } from "firebase/firestore";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { scoreConverter } from "@/libs/scoring/clientScore";
import { useTasksFirestore } from "@/libs/tasks/firestoreTasks";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";

interface TaskContextProps {
  children?: ReactNode;
}

interface TaskContext {
  tasks: Map<string, TaskConfig> | undefined;
  scores: ScoreSchema | undefined;
  event: EventSchema | undefined;
  user: User | null | undefined;
}

const TaskContext = createContext({} as TaskContext);

export default function TaskContextProvider({ children }: TaskContextProps) {
  const [scores, setScores] = useState<ScoreSchema>();
  const [user, loading] = useAuthState(firebaseAuth);

  const tasks = useTasksFirestore();

  useEffect(() => {
    if (!loading && user) {
      async function scoreSub() {
        try {
          const id = await getProjectId(user!);
          const unsubscribe = onSnapshot(
            doc(firestore, "scores", id).withConverter(scoreConverter),
            (doc) => {
              if (doc.data()) {
                setScores(doc.data());
              }
            },
          );
          return () => unsubscribe();
        }
        catch {
          // The user doesn't have a project ID - this is handled elsewhere so is OK to ignore
        }
      }
      scoreSub();
    }
  }, [loading, user]);

  return (
    <TaskContext.Provider
      value={{ tasks: tasks?.tasks, event: tasks?.event, scores, user }}
    >
      {children}
    </TaskContext.Provider>
  );
}

export const Tasks = () => {
  return useContext(TaskContext);
};
