/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { getAnalytics, isSupported, logEvent, setUserProperties } from "firebase/analytics";
import { firebaseApp } from "./clientApp";

export const fbAnalytics = isSupported().then((yes) =>
  yes ? getAnalytics(firebaseApp) : null,
);

interface TaskProps {
  eventId?: string | undefined;
  teamId: string | undefined;
  taskId: string;
  partId: string;
}

export async function submitTask({
  taskId,
  partId,
  teamId,
  eventId = "submit_task",
}: TaskProps) {
  const analytics = await fbAnalytics;
  if (analytics) {
    logEvent(analytics, eventId, {
      taskId: taskId,
      partId: partId,
      teamId: teamId,
    });
  }
}

export async function registerUser(teamId: string) {
  const analytics = await fbAnalytics;
  if (analytics) {
    setUserProperties(analytics, { team_id: teamId });
  }
}
