/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use client";

import { storage } from "@/libs/firebase/clientApp";
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, Timestamp } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import { ScoreSchema } from "@hacksday/libscore";

/**
 * Set of functions to get the score from the client side
 */
export const scoreConverter: FirestoreDataConverter<ScoreSchema> = {
  toFirestore(score: ScoreSchema): DocumentData {
    return {
      totals: score.totals,
      task_totals: Object.fromEntries(score.task_totals),
      tasks: Object.fromEntries(score.tasks),
      bonus_scores: Object.fromEntries(score.bonus_scores),
      screenshots: Object.fromEntries(score.screenshots),
      score_updated: Timestamp.fromDate(score.score_updated),
      reviewed: Object.fromEntries(score.reviewed),
    };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot): ScoreSchema {
    const data = docSnap.data();
    return {
      totals: data.totals,
      task_totals: new Map(Object.entries(data.task_totals || {})),
      bonus_scores: new Map(Object.entries(data.bonus_scores || {})),
      screenshots: new Map(Object.entries(data.screenshots || {})),
      reviewed: new Map(Object.entries(data.reviewed || {})),
      tasks: new Map(Object.entries(data.tasks || {})),
      score_updated: (data.score_updated as Timestamp).toDate(),
    };
  },
};

interface FilenameProps {
  taskId: string;
  partId: string;
  scores: ScoreSchema;
  teamId: string;
  root?: string;
  filename?: string;
}

export async function getFile({
  taskId,
  partId,
  scores,
  teamId,
  root = "tasks",
  filename = "screenshot.png",
}: FilenameProps): Promise<string | undefined> {
  try {
    if (scores.screenshots.has(`${taskId}_${partId}`)) {
      const path = `${root}/${teamId}/${taskId}/${partId}/${filename}`;
      const pr = ref(storage, path);
      const blob = await getBlob(pr);
      return URL.createObjectURL(blob);
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function ogetFile(
  taskId: string,
  partId: string,
  scores: ScoreSchema,
  teamId: string,
  root: string = "tasks",
  filename: string = "screenshot.png"
): Promise<string | undefined> {
  try {
    if (scores.screenshots.has(`${taskId}_${partId}`)) {
      const path = `${root}/${teamId}/${taskId}/${partId}/${filename}`;
      const pr = ref(storage, path);
      const blob = await getBlob(pr);
      return URL.createObjectURL(blob);
    }
  } catch {
    return undefined;
  }
}
